import { setCookie, deleteCookie, getCookie } from 'cookies-next';

import { constants } from 'common';
import { IUser } from 'common/types';

export interface ISessionData {
    accessToken?: string;
    refreshToken?: string;
    firebaseToken?: string;
    userDetails?: IUser;
}

export interface IConfirmNumberData {
    date?: string;
    counter?: number;
}

export const setSession = (accessToken: string, refreshToken: string, userDetails?: IUser) => {
    if (userDetails?.bio) {
        delete userDetails?.bio;
    }

    if (userDetails?.subscriptions) {
        delete userDetails?.subscriptions;
    }

    const data: ISessionData = {
        accessToken,
        refreshToken,
    };

    setCookie(constants.SESSION_KEY, JSON.stringify(data));
    setCookie(constants.USER_DETAILS_KEY, JSON.stringify(userDetails || {}));
};

export const getSession = (): ISessionData => {
    const sessionCookie = getCookie(constants.SESSION_KEY);
    const userDetailsCookie = getCookie(constants.USER_DETAILS_KEY);
    const sessionData = sessionCookie ? JSON.parse(sessionCookie as string) : {};
    const userDetails = userDetailsCookie ? JSON.parse(userDetailsCookie as string) : null;

    if (userDetails) {
        sessionData.userDetails = userDetails;
    }

    return sessionData || {};
};

export const removeSession = () => {
    deleteCookie(constants.SESSION_KEY);
    deleteCookie(constants.USER_DETAILS_KEY);
};

export const setFirebaseToken = (token: string) => {
    const session = Object.assign({}, getSession());
    session.firebaseToken = token;

    setCookie(constants.SESSION_KEY, JSON.stringify(session));
};

export const setUserDetails = (userDetails?: IUser) => {
    setCookie(constants.USER_DETAILS_KEY, JSON.stringify(userDetails || {}));
};

export const getConfirmPhoneDetails = (): IConfirmNumberData => {
    const data = localStorage.getItem(constants.CONFIRM_NUMBER_KEY);

    return data ? JSON.parse(data as string) : {};
};

export const setConfirmPhoneDetails = (data: IConfirmNumberData) => {
    localStorage.setItem(constants.CONFIRM_NUMBER_KEY, JSON.stringify(data));
};

export const removeConfirmNumberData = () => {
    localStorage.removeItem(constants.CONFIRM_NUMBER_KEY);
};

export const getForgotPasswordResendDetails = (): IConfirmNumberData => {
    const data = localStorage.getItem(constants.RESEND_FORGOT_PASSWORD_KEY);

    return data ? JSON.parse(data as string) : {};
};

export const setForgotPasswordResendDetails = (data: IConfirmNumberData) => {
    localStorage.setItem(constants.RESEND_FORGOT_PASSWORD_KEY, JSON.stringify(data));
};
