import { initilizeHttpClient } from 'core/axios-config';
import config from 'core/config';
import getQueryClient from 'core/query-client';
import * as firebase from 'core/firebase';
import {
    setSession,
    getSession,
    removeSession,
    setUserDetails,
    setConfirmPhoneDetails,
    getConfirmPhoneDetails,
    removeConfirmNumberData,
    setFirebaseToken,
    getForgotPasswordResendDetails,
    setForgotPasswordResendDetails,
} from 'core/session';

export {
    initilizeHttpClient,
    config,
    getQueryClient,
    setSession,
    getSession,
    removeSession,
    setUserDetails,
    setConfirmPhoneDetails,
    getConfirmPhoneDetails,
    removeConfirmNumberData,
    setFirebaseToken,
    firebase,
    getForgotPasswordResendDetails,
    setForgotPasswordResendDetails,
};
