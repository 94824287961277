import { helpers } from 'common';
import { ILoginData, IRegistrationData, IConfirPhoneData, IForgotPasswordData, IResetPasswordData } from 'api/auth/types';

const { formAxiosObject } = helpers;

export const loginAPI = async (data: ILoginData) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/login' }, data);

    return response?.data;
};

export const registrationAPI = async (data: IRegistrationData) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/register' }, data);

    return response?.data;
};

export const resendPhoneNumberCodeAPI = async () => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/resend-confirm-phone' }, {});

    return response?.data;
};

export const confirmPhoneCodeAPI = async (data: IConfirPhoneData) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/confirm-phone' }, data);

    return response?.data;
};

export const forgotPasswordAPI = async (data: IForgotPasswordData) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/forgot-password' }, data);

    return response?.data;
};

export const resetPasswordAPI = async (data: IResetPasswordData) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/reset-password' }, data);

    return response?.data;
};

export const logoutAPI = async (token: string) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/logout' }, { token });

    return response?.data;
};

export const createFirebaseAuthTokenAPI = async () => {
    const response = await formAxiosObject({ method: 'GET', route: '/me/firebase-auth' });

    return response?.data?.data;
};

export const confirmEmail = async (token: string) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/confirm-email' }, { token });

    return response?.data?.data;
};

export const verifyMFA = async (otp: string, authToken: string) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/verify-tpa' }, { otp }, null, null, {
        Authorization: `Bearer ${authToken}`,
    });

    return response?.data?.data;
};
