import { ICategory } from 'api/category/types';
import { helpers } from 'common';
import { IPaging } from 'common/types';

const { formAxiosObject } = helpers;

export const getCategoriesAPI = async (search?: string, parent?: boolean): Promise<ICategory[]> => {
    const response = await formAxiosObject({ method: 'GET', route: '/categories/all/' }, null, { search, parent: parent || false });

    return response?.data?.data || [];
};

export const getPopularCategoriesAPI = async (): Promise<ICategory[]> => {
    const response = await formAxiosObject({ method: 'GET', route: '/categories/popular/' });

    return response?.data?.data || [];
};

export const getCategoryFieldsAPI = async (categoryId: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/categories/all/${categoryId}` });

    return response?.data?.data || [];
};

export const getCategoryProductsAPI = async (categoryId: string, params?: IPaging) => {
    const response = await formAxiosObject({ method: 'GET', route: `/categories/all/${categoryId}/products/` }, null, params);

    return response?.data?.data?.results || [];
};

export const getCategoryBySlugAPI = async (slug: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/categories/slugs` }, null, null, { slug });

    return response?.data?.data || {};
};

export const getCategoryFiltersAPI = async (categoryId: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/categories/all/${categoryId}/filters/` });

    return response?.data?.data || [];
};
