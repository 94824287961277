import { helpers } from 'common';
import { IPaging } from 'common/types';
import { ICreateBanner } from 'api/banners/types';

const { formAxiosObject } = helpers;

export const getMyBanners = async (params: IPaging) => {
    const response = await formAxiosObject({ method: 'GET', route: '/me/banners/' }, null, params);

    return response?.data?.data;
};

export const getBannerQuotas = async (categoryID: number, packageID: number) => {
    const response = await formAxiosObject({ method: 'GET', route: `/banners/categories/${categoryID}/packages/${packageID}/quotas/` });

    return response?.data?.data;
};

export const getBannerQuota = async (categoryID: number, packageID: number, quotaID: number) => {
    const response = await formAxiosObject({ method: 'GET', route: `/banners/categories/${categoryID}/packages/${packageID}/quotas/${quotaID}` });

    return response?.data?.data;
};

export const createBanner = async (data: ICreateBanner) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/banners/' }, data);

    return response?.data?.data;
};

export const requestPayment = async (bannerID: number, data: { paymentProvider: number }) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/banners/${bannerID}/requestPayment` }, data);

    return response?.data?.data;
};

export const requestExtendBanner = async (bannerID: number, data: { paymentProvider: number }, monri?: boolean) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/banners/${bannerID}/requestExtendPayment` }, data, { monri: monri || false });

    return response?.data?.data;
};

export const getBannersByCategory = async (categoryID: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/banners/categories/${categoryID}/all/` });

    return response?.data?.data;
};

export const deleteBanner = async (bannerID: number) => {
    const response = await formAxiosObject({ method: 'DELETE', route: `/me/banners/${bannerID}` });

    return response?.data?.data;
};

export const updateBanner = async (bannerID: number, data: ICreateBanner) => {
    const response = await formAxiosObject({ method: 'PUT', route: `/me/banners/${bannerID}` }, data);

    return response?.data?.data;
};

export const requestReviewBannerAPI = async (bannerID: number) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/banners/${bannerID}/requestReview` });

    return response?.data?.data;
};
