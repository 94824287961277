import { constants } from 'common';

export const defaultNS = 'translation';

export function getOptions(lng = constants.DEFAULT_LANGUEG, ns = defaultNS) {
    return {
        // debug: true,
        supportedLngs: constants.LANGUAGES.map((l) => l.code),
        fallbackLng: constants.DEFAULT_LANGUEG,
        lng,
        fallbackNS: defaultNS,
        defaultNS,
        ns,
    };
}
