import { helpers } from 'common';

const { formAxiosObject } = helpers;
import { IProductSave, IProductFieldSave, ISearchParams, IFilterData, IGetProductsFilters, IProductReview, IProductOffer, IPromoteProduct, IReportProduct } from 'api/product/types';
import { IPaging } from 'common/types';

export const getProductDetailsAPI = async (productId: string) => {
    const response = await formAxiosObject({ method: 'GET', route: '/me/products' }, null, null, { productId });

    return response?.data?.data;
};

export const saveProductAPI = async (data: IProductSave) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/products/' }, data);

    return response?.data?.data;
};

export const updateProductAPI = async (productId: string, data: IProductSave) => {
    const response = await formAxiosObject({ method: 'PUT', route: `/me/products/${productId}` }, data);

    return response?.data?.data;
};

export const getProductImagesAPI = async (productId: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/me/products/${productId}/images/` });

    return response?.data?.data || [];
};

export const saveProductImagesAPI = async (productId: string, imageURIs: string[] | null, deleteOldImages?: boolean) => {
    const response = await formAxiosObject({ method: 'PUT', route: `/me/products/${productId}/images/` }, { deleteOldImages: deleteOldImages || false, images: imageURIs });

    return response?.data?.data || [];
};

export const deleteProductImageAPI = async (productId: string, imageId: string) => {
    const response = await formAxiosObject({ method: 'DELETE', route: `/me/products/${productId}/images/${imageId}` });

    return response?.data?.data || [];
};

export const starProductImageAPI = async (productId: string, imageId: string) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/products/${productId}/images/${imageId}` });

    return response?.data?.data || [];
};

export const saveProductFieldsAPI = async (productId: string, data: IProductFieldSave) => {
    const response = await formAxiosObject({ method: 'POST', route: `/me/products/${productId}/fields/` }, data);

    return response?.data?.data || [];
};

export const updateProductFieldAPI = async (productId: string, fieldId: string, choice?: string | null) => {
    const response = await formAxiosObject({ method: 'PUT', route: `/me/products/${productId}/fields/${fieldId}` }, { choice: choice || null });

    return response?.data?.data || [];
};

export const publishProductAPI = async (productId: string) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/products/${productId}` });

    return response?.data?.data || [];
};

export const searchProductAPI = async (params?: ISearchParams, data?: IFilterData) => {
    const response = await formAxiosObject({ method: 'POST', route: `/products/search/` }, data, params);

    return response?.data?.data || {};
};

export const getProductDetailsPublicAPI = async (productId: string) => {
    const response = await formAxiosObject({ method: 'GET', route: '/products/all' }, null, null, { productId });

    return response?.data?.data;
};

export const getProductsPublicAPI = async (params?: ISearchParams, data?: IGetProductsFilters) => {
    const response = await formAxiosObject({ method: 'GET', route: '/products/all/' }, null, { ...(params || {}), ...(data || {}) });

    return response?.data?.data;
};

export const getMyProductsAPI = async (params?: ISearchParams, data?: IGetProductsFilters) => {
    const response = await formAxiosObject({ method: 'GET', route: '/me/products/' }, null, { ...(params || {}), ...(data || {}) });

    return response?.data?.data;
};

export const deleteProductAPI = async (uuid: string) => {
    const response = await formAxiosObject({ method: 'DELETE', route: `/me/products/${uuid}` });

    return response?.data?.data;
};

export const unpublishProductAPI = async (uuid: string) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/products/${uuid}/unpublish/` });

    return response?.data?.data;
};

export const reviewProductPI = async (uuid: string, data: IProductReview) => {
    const response = await formAxiosObject({ method: 'POST', route: `/products/all/${uuid}/reviews/` }, data);

    return response?.data?.data;
};

export const getProductReviewsAPI = async (uuid: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/products/all/${uuid}/reviews/` });

    return response?.data?.data;
};

export const productOfferAPI = async (uuid: string, data: IProductOffer) => {
    const response = await formAxiosObject({ method: 'POST', route: `/products/all/${uuid}/offers/` }, data);

    return response?.data?.data;
};

export const getProductPromoteQuotasAPI = async (params?: IPaging) => {
    const response = await formAxiosObject({ method: 'GET', route: `/products/promotionQuotas/` }, null, params);

    return response?.data?.data?.results;
};

export const promoteProductAPI = async (uuid: string, data: IPromoteProduct) => {
    const response = await formAxiosObject({ method: 'PUT', route: `/me/products/${uuid}/promote/` }, data);

    return response?.data?.data;
};

export const reportProductAPI = async (uuid: string, data: IReportProduct) => {
    const response = await formAxiosObject({ method: 'POST', route: `/products/all/${uuid}/report/` }, data);

    return response?.data?.data || {};
};

export const updateProductViewsAPI = async (uuid: string) => {
    const response = await formAxiosObject({
        method: 'PATCH',
        route: `/products/all/${uuid}`,
    });

    return response?.data?.data || {};
};

export const putProductVideoStatusToPendingAPI = async (uuid: string) => {
    const response = await formAxiosObject({
        method: 'PATCH',
        route: `/me/products/${uuid}/video/`,
    });

    return response?.data?.data || {};
};

export const getUserProductsByCategory = async (categoryID: number) => {
    const response = await formAxiosObject({ method: 'GET', route: `/me/categories/${categoryID}/products/count` });

    return response?.data?.data;
};
