import { IRegion } from 'api/region/types';
import { helpers } from 'common';

const { formAxiosObject } = helpers;

export const getRegionsAPI = async (page?: number, perPage?: number): Promise<IRegion[]> => {
    const response = await formAxiosObject({ method: 'GET', route: '/regions/' }, null, { page, perPage });

    return response?.data?.data?.results || [];
};
