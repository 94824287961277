import { RawAxiosRequestHeaders } from 'axios';

import { helpers } from 'common';
import { IUserUpdate, IUserAddressSave, IReportUser, IUserOrderReview, IUserSubscription } from 'api/user/types';
import { IPaging } from 'common/types';

const { formAxiosObject } = helpers;

export const updateUserAPI = async (data: IUserUpdate) => {
    const response = await formAxiosObject({ method: 'PUT', route: '/me/' }, data);

    return response?.data;
};

export const getUserDetailsAPI = async (headers?: RawAxiosRequestHeaders) => {
    const response = await formAxiosObject({ method: 'GET', route: '/me/' }, null, null, null, headers);

    return response?.data;
};

export const getUserAddressesAPI = async () => {
    const response = await formAxiosObject({ method: 'GET', route: '/me/addresses/' });

    return response?.data?.data;
};

export const saveUserAddressesAPI = async (data: IUserAddressSave, verification?: boolean) => {
    const response = await formAxiosObject({ method: 'POST', route: '/me/addresses/' }, data, { verification: verification || false });

    return response?.data?.data;
};

export const updateUserAddressesAPI = async (addressUUID: string, data: IUserAddressSave) => {
    const response = await formAxiosObject({ method: 'PUT', route: `/me/addresses/${addressUUID}` }, data);

    return response?.data?.data;
};

export const getSellerAddressesAPI = async (userUUID?: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/users/all/${userUUID || ''}/addresses/` });

    return response?.data?.data;
};

export const getBuyerAddressAPI = async (userUUID?: string, addressUUID?: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/users/all/${userUUID || ''}/addresses/${addressUUID || ''}` });

    return response?.data?.data;
};

export const getUserDetailsPublicAPI = async (userUUID: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/users/all/${userUUID}` });

    return response?.data?.data;
};

export const getUserRatingAPI = async (userUUID: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/users/all/${userUUID}/rating/` });

    return response?.data?.data;
};

export const getUserDetailsPublicByUsernameAPI = async (username: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/users/usernames/${username}` });

    return response?.data?.data;
};

export const getUserReviewsAPI = async (userUUID: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/users/all/${userUUID}/reviews/` });

    return response?.data?.data;
};

export const getUserCategoriesAPI = async (userUUID: string, params?: any) => {
    const response = await formAxiosObject({ method: 'GET', route: `/users/all/${userUUID}/categories/` }, null, params);

    return response?.data?.data?.results || [];
};

export const reportUserAPI = async (userUUID: string, data: IReportUser) => {
    const response = await formAxiosObject({ method: 'POST', route: `/users/all/${userUUID}/report/` }, data);

    return response?.data?.data || {};
};

export const getUserCoverImagesAPI = async (userUUID: string) => {
    const response = await formAxiosObject({ method: 'GET', route: `/users/all/${userUUID}/coverImages/` });

    return response?.data?.data || [];
};

export const updateUserCoverImagesAPI = async (images: string[], deleteOldImages?: boolean) => {
    const response = await formAxiosObject({ method: 'PUT', route: `/me/coverImages/` }, { deleteOldImages, images });

    return response?.data?.data || [];
};

export const verifyUserPasswordAPI = async (password: string) => {
    const response = await formAxiosObject({ method: 'POST', route: `/me/sudo-password` }, { password });

    return response?.data?.data;
};

export const changePasswordAPI = async (token: string, password: string) => {
    const response = await formAxiosObject({ method: 'POST', route: `/me/change-password` }, { token, password });

    return response?.data?.data;
};

export const deleteProfileAPI = async (token: string) => {
    const response = await formAxiosObject({ method: 'DELETE', route: `/me/` }, { token });

    return response?.data?.data;
};

export const enableMFA = async (token: string, otp: string, secret: string) => {
    const response = await formAxiosObject({ method: 'POST', route: `/me/enable-tpa` }, { token, otp, secret });

    return response?.data?.data;
};

export const disableMFA = async (token: string) => {
    const response = await formAxiosObject({ method: 'POST', route: `/me/disable-tpa` }, { token });

    return response?.data?.data;
};

export const getNotificationsCountAPI = async () => {
    const response = await formAxiosObject({ method: 'GET', route: `/notifications/unread/count/` });

    return response?.data?.data;
};

export const getNotificationsAPI = async (params: IPaging) => {
    const response = await formAxiosObject({ method: 'GET', route: `/notifications/me/` }, null, params);

    return response?.data?.data;
};

export const makeNotificationViewedAPI = async (notifications: string[]) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/notifications/me/` }, { notifications });

    return response?.data?.data;
};

export const reportReviewAPI = async (userUUID: string, reviewUUID: string, data: IReportUser) => {
    const response = await formAxiosObject({ method: 'POST', route: `/users/all/${userUUID}/reviews/${reviewUUID}/report/` }, data);

    return response?.data?.data || {};
};

export const leaveUserReview = async (userUUID: string, data: IUserOrderReview) => {
    const response = await formAxiosObject({ method: 'POST', route: `/users/all/${userUUID}/reviews/` }, data);

    return response?.data?.data || {};
};

export const cancelSubscriptionAPI = async (subscriptionID: string) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/subscriptions/${subscriptionID}/cancel` });

    return response?.data?.data || {};
};

export const changeSubscriptionAPI = async (subscriptionID: string, productID: string, variantID: string) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/subscriptions/${subscriptionID}/change` }, { productID: parseInt(productID, 10), variantID: parseInt(variantID, 10) });

    return response?.data?.data || {};
};

export const getSubscriptionQuotasAPI = async () => {
    const response = await formAxiosObject({ method: 'GET', route: `/me/subscriptionQuotas/` });

    return response?.data?.data || {};
};

export const createSubscriptionAPI = async (data: IUserSubscription) => {
    const response = await formAxiosObject({ method: 'POST', route: `/me/subscriptions/` }, data);

    return response?.data?.data || {};
};

export const upgradeSubscriptionAPI = async (subscriptionUUID: string, quotaID: number) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/subscriptions/${subscriptionUUID}/upgrade` }, { subscriptionQuotaID: quotaID });

    return response?.data?.data || {};
};

export const renewSubscriptionAPI = async (subscriptionUUID: string) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/subscriptions/${subscriptionUUID}/renew` });

    return response?.data?.data || {};
};

export const cancelRenewSubscriptionAPI = async (subscriptionUUID: string) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/subscriptions/${subscriptionUUID}/renewCancel` });

    return response?.data?.data || {};
};

export const cancelUpgradeSubscriptionAPI = async (subscriptionUUID: string, quotaID: number) => {
    const response = await formAxiosObject({ method: 'PATCH', route: `/me/subscriptions/${subscriptionUUID}/upgradeCancel` }, { subscriptionQuotaID: quotaID });

    return response?.data?.data || {};
};
