// Global constants in the project. These should be constants that will be re-used in multiple places. Dont'a place here the constants related to some feature!

const constants = {
    // eslint-disable-next-line
    EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    // eslint-disable-next-line
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\W]{8,25}$/,
    USERNAME_REGEX: /^[A-Za-z0-9-_.]{4,20}$/,
    PHONE_NUMBER_REGEX: /^(?!-)(\d{7,15})$/,
    NUMBER_DECIMALS_REGEX: /^\d+(\.\d{0,2})?$/,
    LOCALES: ['ba'],
    LANGUAGES: [
        {
            code: 'ba',
            name: 'Bosanski',
            shortened: 'bs',
        },
    ],
    DEFAULT_LANGUEG: 'ba',
    SESSION_KEY: 'session-key',
    COUNTRIES: [
        {
            label: 'Bosna i Hercegovina',
            value: '+387',
            leftElement: '🇧🇦',
            code: 'BA',
        },
        {
            label: 'Hrvatska',
            value: '+385',
            leftElement: '🇭🇷',
        },
        {
            label: 'Srbija',
            value: '+381',
            leftElement: '🇷🇸',
        },
    ],
    MAX_UPLOAD_SIZE: {
        productImages: 10485760,
        otherImages: 2097152,
    },
    MAX_DIMENSIONS_BANNER: {
        web: {
            width: 120,
            height: 600,
        },
        mobile: {
            width: 360,
            height: 180,
        },
    },
    INPUT_TYPES: {
        text: {
            value: 1,
            label: 'Text Field',
        },
        select: {
            value: 2,
            label: 'Dropdown',
        },
        checkbox: {
            value: 3,
            label: 'Checkbox',
        },
        number: {
            value: 4,
            label: 'Input Number',
        },
        range: {
            value: 5,
            label: 'Range',
        },
        toggle: {
            value: 6,
            label: 'Toggle',
        },
    },
    CURRENCIES: {
        BAM: {
            value: 'BAM',
            code: 'KM',
            name: 'Konvertibilna Marka',
        },
    },
    CONFIRM_NUMBER_KEY: 'confirm-number-key',
    HIGLIGHT_ARTICLE_OPTIONS: [
        {
            value: 5,
            price: 15,
        },
        {
            value: 3,
            price: 15,
        },
        {
            value: 2,
            price: 15,
        },
    ],
    PAGING: {
        page: 1,
        perPage: 20,
        productsPerPage: 60,
    },
    PAYMENT_PROVIDERS: {
        cash: {
            value: 1,
            label: 'Cash',
        },
        creditCard: {
            value: 2,
            label: 'Credit Card',
            maxAmount: 5000,
        },
        crypto: {
            value: 2,
            label: 'Crypto',
            maxAmount: 5000,
        },
        bankTransfer: {
            value: 3,
            label: 'Bank Transfer',
        },
        inAppPurchases: {
            value: 4,
            label: 'In App Purchases',
        },
        wallet: {
            value: 5,
            label: 'Wallet',
        },
        lemon: {
            value: 6,
            label: 'Lemon Squeezy',
        },
    },
    DEFAULT_DELIVERY_PRICE: 8,
    EXPRESS_DELIVERY_PRICE: 2,

    EXPRESS_DELIVERY_STATUSES: [
        {
            value: 0,
            label: 'DELIVERY_STATUS_CREATED',
            icon: 'file-check',
            iconClass: 'yellow-icon',
        },
        {
            value: 2,
            label: 'DELIVERY_STATUS_PICKED_UP_BY_COURIER',
            icon: 'truck',
            iconClass: 'green-icon',
        },
        {
            value: 8,
            label: 'DELIVERY_STATUS_IN_TRANSIT',
            icon: 'truck',
            iconClass: 'green-icon',
        },
        {
            value: 11,
            label: 'DELIVERY_STATUS_PICKED_UP_BY_DELIVERY_COURIER',
            icon: 'truck',
            iconClass: 'green-icon',
        },
        {
            value: 17,
            label: 'DELIVERY_STATUS_DELAYED',
            icon: 'truck',
            iconClass: 'green-red',
        },
        {
            value: 19,
            label: 'DELIVERY_STATUS_RETURNED',
            icon: 'truck',
            iconClass: 'red-icon',
            skipStatuses: [17],
        },
        {
            value: 20,
            label: 'DELIVERY_STATUS_DELIVERED',
            icon: 'truck',
            iconClass: 'green-icon',
            skipStatuses: [17, 19],
        },
        {
            value: 29,
            label: 'DELIVERY_STATUS_ATTEMPTED_DELIVERY',
            icon: 'truck',
            iconClass: 'green-icon',
            skipStatuses: [20],
        },
        {
            value: 98,
            label: 'DELIVERY_STATUS_LOST',
            icon: 'file-check',
            iconClass: 'red-icon',
            skipStatuses: [17, 19, 20, 29],
        },
    ],
    REGULAR_DELIVERY_STATUSES: [
        {
            value: 1,
            label: 'ORDER_CREATED',
            buyerLabel: 'ORDER_CREATED_BUYER',
            icon: 'file-check',
            iconClass: 'yellow-icon',
        },
        {
            value: 2,
            label: 'ORDER_STARTED',
            buyerLabel: 'ORDER_STARTED_BUYER',
            icon: 'file-check',
            iconClass: 'green-icon',
        },
        {
            value: 3,
            label: 'ORDER_ACCEPTED',
            buyerLabel: 'ORDER_ACCEPTED_BUYER',
            icon: 'file-check',
            iconClass: 'green-icon',
        },
        {
            value: 4,
            label: 'ORDER_DECLINED',
            buyerLabel: 'ORDER_DECLINED_BUYER',
            icon: 'file-check',
            iconClass: 'red-icon',
        },
        {
            value: 5,
            label: 'ORDER_PROCESSING',
            buyerLabel: 'ORDER_PROCESSING_BUYER',
            icon: 'file-check',
            iconClass: 'green-icon',
        },
        {
            value: 6,
            label: 'ORDER_COMPLETED',
            buyerLabel: 'ORDER_COMPLETED_BUYER',
            icon: 'credit-card-check',
            iconClass: 'green-icon',
        },
    ],
    BASE_INSTAGRAM_URL: 'https://www.instagram.com',
    BASE_FACEBOOK_URL: 'https://www.facebook.com',
    // eslint-disable-next-line
    INSTAGRAM_PROFILE_REGEX: /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_\.]+\/?$/,
    // eslint-disable-next-line
    FACEBOOK_PROFILE_REGEX: /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/gi,
    // eslint-disable-next-line
    URL_REGEX: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/,
    // eslint-disable-next-line
    BASE_CHARS_REGEX: /^[A-Za-z0-9-_.,ŠšĐđČčĆćŽž ]+$/,
    // eslint-disable-next-line
    DESCRIPTION_REGEX: /^[A-Za-z0-9\-_.,ŠšĐđČčĆćŽž\s+()%;!?/:\n\\]+$/,
    // eslint-disable-next-line
    YOU_TUBE_REGEX: /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([-\w]{11})(?:\S*)?$/,
    USER_STATUSES: {
        initial: {
            value: 1,
            label: 'INITIAL_STATUS',
        },
        pending: {
            value: 2,
            label: 'PENDING_STATUS',
        },
        verified: {
            value: 3,
            label: 'VERIFIED_STATUS',
        },
        declined: {
            value: 4,
            label: 'DECLINED_STATUS',
        },
    },
    ORDER_STATUSES: {
        created: {
            value: 1,
            label: 'ORDER_CREATED',
            icon: 'file-check',
            iconClass: 'orange-icon',
        },
        started: {
            value: 2,
            label: 'ORDER_STARTED',
            icon: 'file-check',
            iconClass: 'green-icon',
        },
        payed: {
            value: 0,
            label: 'ORDER_PAYED',
            isPayed: true,
            icon: 'credit-card-check',
            iconClass: 'green-icon',
            subtitle: 'PAYED_STATUS_SUBTITLE',
        },
        accepted: {
            value: 3,
            label: 'ORDER_ACCEPTED',
            icon: 'file-check',
            iconClass: 'green-icon',
        },
        declined: {
            value: 4,
            label: 'ORDER_DECLINED',
            icon: 'file-check',
            iconClass: 'red-icon',
            subtitle: 'REJECTED_STATUS_SUBTITLE',
        },
        processed: {
            value: 5,
            label: 'ORDER_PROCESSED',
            icon: 'file-check',
            iconClass: 'green-icon',
        },
        completed: {
            value: 6,
            label: 'ORDER_COMPLETED',
            icon: 'file-check',
            iconClass: 'green-icon',
        },
    },
    BANNER_PACKAGES: {
        web: {
            value: 1,
            label: 'Web',
        },
        mobile: {
            value: 2,
            label: 'Mobile',
        },
        webAndMobile: {
            value: 3,
            label: 'Web+Mobile',
        },
    },
    BANNER_STATUSES: {
        1: {
            value: 1,
            label: 'BANNER_STATUS_CRATED',
            background: 'bg-green-300',
            color: 'text-green-100',
        },
        2: {
            value: 2,
            label: 'BANNER_STATUS_PAYMENT_PENDING',
            background: 'bg-orange-100',
            color: 'text-orange-200',
        },
        3: {
            value: 3,
            label: 'BANNER_STATUS_REJECTED',
            background: 'bg-green-300',
            color: 'text-green-100',
        },
        4: {
            value: 4,
            label: 'BANNER_STATUS_REQUESTED',
            background: 'bg-orange-100',
            color: 'text-orange-200',
        },
        5: {
            value: 5,
            label: 'BANNER_STATUS_ACCEPTED',
            background: 'bg-orange-100',
            color: 'text-orange-200',
        },
        6: {
            value: 6,
            label: 'BANNER_STATUS_EXPIRED',
            background: 'bg-orange-100',
            color: 'text-orange-200',
        },
        7: {
            value: 7,
            label: 'BANNER_STATUS_REJECTED',
            background: 'bg-orange-100',
            color: 'text-orange-200',
        },
    },
    NOTIFICATION_TYPES: {
        notificationTypeOfferAccepted: {
            value: 1,
            label: 'NOTIFICATION_TYPE_OFFER_ACCEPTED',
            description: '',
        },
        notificationTypeOfferCreated: {
            value: 2,
            label: 'NOTIFICATION_TYPE_OFFER_CREATED',
        },
        notificationTypeOfferRejected: {
            value: 3,
            label: 'NOTIFICATION_TYPE_OFFER_REJECTED',
        },
        notificationTypeOrderAccepted: {
            value: 4,
            label: 'NOTIFICATION_TYPE_ORDER_ACCEPTED',
        },
        notificationTypeProductPromotionExpired: {
            value: 5,
            label: 'NOTIFICATION_TYPE_PRODUCT_PROMOTION_EXPIRED',
        },
        notificationTypeVerificationRejected: {
            value: 6,
            label: 'NOTIFICATION_TYPE_VERIFICATION_REJECTED',
        },
        notificationTypeVerificationAccepted: {
            value: 7,
            label: 'NOTIFICATION_TYPE_VERIFICATION_ACCEPTED',
        },
        notificationTypeBannerAccepted: {
            value: 8,
            label: 'NOTIFICATION_TYPE_BANNER_ACCEPTED',
        },
        notificationTypeBannerExpired: {
            value: 9,
            label: 'NOTIFICATION_TYPE_BANNER_EXPIRED',
        },
        notificationTypeBannerDeclined: {
            value: 10,
            label: 'NOTIFICATION_TYPE_BANNER_REJECTED',
        },
        notificationTypeReportCreated: {
            value: 11,
            label: 'NOTIFICATION_TYPE_REPORT_CREATED',
        },
        notificationTypeOrderCreated: {
            value: 12,
            label: 'NOTIFICATION_TYPE_ORDER_CREATED',
        },
        notificationTypeOrderRejected: {
            value: 13,
            label: 'NOTIFICATION_TYPE_ORDER_REJECTED',
        },
        notificationTypeAddressApproved: {
            value: 14,
            label: 'NOTIFICATION_TYPE_ADDRESS_APPROVED',
        },
        notificationTypeAddressRejected: {
            value: 15,
            label: 'NOTIFICATION_TYPE_ADDRESS_REJECTED',
        },
        notificationTypeBankTransferAccepted: {
            value: 16,
            label: 'NOTIFICATION_TYPE_BANK_TRANSFER_ACCEPTED',
        },
        notificationTypeBankTransferRejected: {
            value: 17,
            label: 'NOTIFICATION_TYPE_BANK_TRANSFER_REJECTED',
        },
        notificationTypeOnlinePaymentVerified: {
            value: 18,
            label: 'NOTIFICATION_TYPE_ONLINE_PAYMENT_VERIFIED',
        },
        notificationTypeOnlinePaymentRejected: {
            value: 19,
            label: 'NOTIFICATION_TYPE_ONLINE_PAYMENT_REJECTED',
        },
    },
    RESEND_FORGOT_PASSWORD_KEY: 'resend-forgot-password-key',
    FACEBOOK: 'https://www.facebook.com/profile.php?id=61557455492600',
    INSTAGRAM: 'https://www.instagram.com/naklik.ba/',
    TIK_TOK: 'https://www.tiktok.com/@naklik.ba',
    TWITTER: 'https://twitter.com/home',
    GOOGLE_PLAY_LINK: 'https://play.google.com/store/apps/details?id=com.naklik.prd',
    PLAY_STORE_LINK: 'https://apps.apple.com/bs/app/naklik/id6477913737',
    SUPPORT_EMAIL: 'podrska@naklik.ba',
    VIDEO_PAY_DEFAULT_AMOUNT: 299,
    PRODUCT_VIDEO_STATUSES: {
        created: {
            value: 1,
        },
        pending: {
            value: 2,
        },
        accepted: {
            value: 3,
        },
    },
    SUBSCRIPTION_STATUSES: {
        created: {
            value: 1,
        },
        paid: {
            value: 2,
        },
        requested: {
            value: 3,
        },
        canceled: {
            value: 4,
        },
    },
    ONLINE_PAYMENT_VERIFICATION_STATUSES: {
        initiated: {
            value: 1,
        },
        pending: {
            value: 2,
        },
        verified: {
            value: 3,
        },
        rejected: {
            value: 4,
        },
    },
    USER_DETAILS_KEY: 'user-details',
};

export default constants;
