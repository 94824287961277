import { helpers } from 'common';
import { IPaging } from 'common/types';
import { IWallerOrder } from 'api/wallet/types';

const { formAxiosObject } = helpers;

export const getWalletQuotasAPI = async (params: IPaging) => {
    const response = await formAxiosObject({ method: 'GET', route: '/walletQuotas/' }, null, params);

    return response?.data?.data?.results;
};

export const createWalletOrderAPI = async (data: IWallerOrder) => {
    const response = await formAxiosObject({ method: 'PUT', route: '/me/wallet/' }, data);

    return response?.data?.data;
};
