import { authAPI } from 'api';
import { initializeApp, getApps } from 'firebase/app';
import { signInWithCustomToken, getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { config, getSession, setFirebaseToken } from 'core';

const firebaseConfig = {
    apiKey: config.FIREBASE_API_KEY,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    projectId: config.FIREBASE_PROJECT_ID,
    storageBucket: config.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
    appId: config.FIREBASE_APP_ID,
};

const { createFirebaseAuthTokenAPI } = authAPI;

let db: any;
let auth: any;
let authenticationInProgress = false;

const init = () => {
    try {
        const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
        db = getFirestore(firebaseApp);
        auth = getAuth();
    } catch (error) {
        console.log('Initialize firebase error: ', error);
    }
};

export const signInToFirebase = async (token: string) => {
    try {
        await signInWithCustomToken(auth, token);
    } catch (error) {
        const newToken = await createFirebaseAuthTokenAPI();
        setFirebaseToken(newToken || '');
        await signInWithCustomToken(auth, newToken);
    }
};

export const authenticateWithFirebase = async () => {
    if (authenticationInProgress) {
        return;
    }

    try {
        authenticationInProgress = true;
        const session = getSession();
        let token = session?.firebaseToken;

        if (!token) {
            token = await createFirebaseAuthTokenAPI();
            setFirebaseToken(token || '');
        }

        await signInToFirebase(token || '');
        authenticationInProgress = false;
    } catch (error) {
        authenticationInProgress = false;
        console.error('Firebase authentication failed', error);
    }
};

export const getCurrentUser = async () => {
    try {
        const response = await getAuth();
        return response?.currentUser;
    } catch (error) {
        return {};
    }
};

export { db, auth };

init();
