const queryKeys = {
    CATEGORIES: 'categories',
    POPULAR_CATEGORIES: 'popular_categories',
    PRODUCT_IMAGES: 'product_images',
    CATEGORY_FIELDS: 'category_fields',
    PRODUCT_DETAILS: 'product_details',
    REGIONS: 'regions',
    SEARCH_PRODUCTS: 'search_products',
    GLOBAL_SEARCH: 'global_search',
    CATEGORY_PRODUCTS: 'category_products',
    CATEGORY_FILTERS: 'category_filters',
    USER_ADDRESSES: 'user_addresses',
    PRODUCT_DETAILS_PUBLIC: 'product_details_public',
    ORDER_DETAILS: 'order_details',
    SELLER_ADDRESSES: 'seller_addresses',
    USER_DETAILS_PUBLIC: 'user_details_public',
    USER_RATING: 'user_rating',
    USER_REVIEWS: 'user_reviews',
    USER_CATEGORIES: 'user_categories',
    USER_PRODUCTS: 'user_products',
    USER_DETAILS: 'user_details',
    USER_COVER_IMAGES: 'user_cover_images',
    GET_ORDERS: 'get_orders',
    GET_PURCHASES: 'get_purchases',
    GET_OFFERS: 'get_offers',
    GET_BUYER_ADDRESS: 'get_buyer_address',
    USER_PRODUCTS_OLD: 'user_products_old',
    GET_PRODUCT_REVIEWS: 'get_product_reviews',
    GET_PRODUCT_PROMOTE_QUOTAS: 'get_product_promote_quotas',
    GET_MY_BANNERS: 'get_my_banners',
    GET_BANNER_QUOTAS: 'get_banner_quotas',
    GET_BANNER_QUOTA: 'get_banner_quota',
    GET_BANNERS_BY_CATEGORY: 'get_banners_by_category',
    GET_NOTIFICATIONS_COUNT: 'get_notifications_count',
    GET_NOTIFICATIONS: 'get_notifications',
    GET_CREATED_ORDERS_NOT_ACCEPTED: 'get_created_orders_not_accepted',
    GET_TOS_URL: 'get_tos_url',
    GET_SELLER_ADDRESSES: 'get_seller_addresses',
    GET_MY_ADDRESSES: 'get_my_addresses',
    GET_USER_REVIEWS: 'get_user_reviews',
    GET_CATEGORY_FIELDS: 'get_category_fields',
    UPDATE_PRODUCT_VIEWS: 'update_product_views',
    GET_PARENT_CATEGORIES: 'get_parent_categories',
    GET_BUYER_REVIEWS: 'get_buyer_reviews',
    GET_RANDOM_PRODUCTS: 'get_random_products',
    GET_HOME_PAGE_PRODUCTS: 'get_home_page_products',
    GET_USER_DETAILS_MAIN: 'get_user_details_main',
    GET_USER_PROFILE_PRODUCTS: 'get_user_profile_products',
};

export default queryKeys;
