import * as authAPI from 'api/auth';
import * as userAPI from 'api/user';
import * as productAPI from 'api/product';
import * as categoryAPI from 'api/category';
import * as sharedAPI from 'api/shared';
import * as regionAPI from 'api/region';
import * as orderAPI from 'api/order';
import * as bannersAPI from 'api/banners';
import * as walletAPI from 'api/wallet';

export { authAPI, userAPI, productAPI, categoryAPI, sharedAPI, regionAPI, orderAPI, bannersAPI, walletAPI };
