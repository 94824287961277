import { helpers } from 'common';
import { IUpload } from 'api/shared/types';

const { formAxiosObject } = helpers;

export const getUploadUrl = async (data: IUpload) => {
    const response = await formAxiosObject({ method: 'POST', route: '/upload' }, data);

    return response?.data?.data;
};

export const uploadToAws = async (file: File, url: string, index: number, onUploadProgress?: (_percentage: number, _index: number, _name: string) => void) => {
    const config = {
        onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

            if (onUploadProgress) {
                onUploadProgress(percentCompleted, index, file.name);
            }
        },
        headers: {
            'Content-Type': file.type,
            'Access-Control-Allow-Origin': '*',
            'x-goog-acl': 'public-read',
        },
        isExternalRequest: true,
    };

    const response = await formAxiosObject(
        {
            route: url,
            method: 'PUT',
        },
        file,
        null,
        null,
        {
            'Content-Type': file.type,
            'Access-Control-Allow-Origin': '*',
            'x-goog-acl': 'public-read',
        },
        true,
        config,
    );

    return response?.data?.data;
};

export const isImageAvailable = async (url: string) => {
    try {
        const response = await formAxiosObject({ method: 'GET', route: url }, null, null, null, null, true);

        return response?.status === 200;
    } catch (error) {
        return false;
    }
};

export const searchAPI = async (query?: string) => {
    const response = await formAxiosObject({ method: 'GET', route: '/search' }, null, null, { query });

    return response?.data?.data?.suggest || {};
};

export const getTosAPI = async () => {
    const response = await formAxiosObject({ method: 'GET', route: '/tos' });

    return response?.data?.data;
};
